import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ContentLoader from "react-content-loader";
import { getMoqsByCategoryId } from "./../store/actions/MoqActions";
import instance from "./../utils/instance";
import MOQ from "./MOQ";

const BlockLoader = ({ height = 300, radius = 8, uniqueKey = "unique" }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    speed={2}
    width={`100%`}
    height={height}
    backgroundColor="#dedede"
    foregroundColor="#f5f5f5"
  >
    <rect
      x="0"
      y="0"
      rx={`${radius}`}
      ry={`${radius}`}
      width="100%"
      height={`${height}`}
    />
  </ContentLoader>
);

const CategoryMOQsCard = (props) => {
  const { category } = props;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getMoqs() {
      try {
        setLoading(true);
        const response = await instance.get(`moq/category/${category.id}`);
        let data = [];
        if (response.data.moqs) {
          data = response.data.moqs;
          setList(data.splice(0, 4));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }

    getMoqs();
  }, [category]);

  if (list.length === 0 && !loading) {
    return;
  }

  return (
    <div className="bg-[#ECEBEB] dark:bg-[#5B5B5B]/30 rounded-[10px] w-full flex flex-col px-4 py-5 space-y-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-tangerine font-bold uppercase text-sm">
          {category.name}
        </h1>

        <button
          className="text-white font-bold bg-[#A68F69] shadow rounded-lg px-3 pb-1"
          onClick={() => (window.location.href = `/categories/${category.slug}`)}
        >
          <span className="text-white m-0 p-0">see more</span>
        </button>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {loading && <BlockLoader uniqueKey="key1" height={320} radius={6} />}
        {loading && <BlockLoader uniqueKey="key1" height={320} radius={6} />}
        {loading && <BlockLoader uniqueKey="key1" height={320} radius={6} />}
        {loading && <BlockLoader uniqueKey="key1" height={320} radius={6} />}
        {!loading &&
          list
            .sort((a, b) => {
              const dateA = new Date(a.date_created);
              const dateB = new Date(b.date_created);

              // Compare the dates in descending order
              return dateB - dateA;
            })
            .map((moq, i) => <MOQ key={i} moq={moq} />)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    moqs: state.moqs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getMoqsByCategoryId }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMOQsCard);
