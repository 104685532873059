import React, { useEffect, useState } from 'react'
import withRouter from '../utils/withRouter';
import Layout from './../layouts/Main';
import MOQ from './../components/MOQ'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchMoqByTerm } from './../store/actions/MoqActions';

function Search(props) {

    const { moqs: { search_moqs } } = props

    const [term, setTerm] = useState('')

    const [searchParams] = useSearchParams();

    useEffect(() => {

        let queryString = searchParams.get('q')

        if (queryString) {
            
            setTerm(queryString)

            fetchTerm(queryString)
        
        }

        async function fetchTerm (value) {

            try {

                await props?.searchMoqByTerm(value)

            } catch (error) {

                if (error?.response?.data && error?.response?.data?.message) {

                    toast.error(`${error?.response?.data?.message}`, { autoClose: 10000 })

                }

                console.log(error)

            }

        }

    }, [])

    return (

        <Layout>

            <h1 className="text-tangerine font-bold uppercase mt-2">Search Results for {term}</h1>

            <div className="grid grid-cols-2 md:grid-cols-5 gap-2 py-5">

                {search_moqs.map((moq, i) => <MOQ key={i} moq={moq} />)}

            </div>

        </Layout>

    )

};

const mapStateToProps = (state) => {

    return {

        moqs: state.moqs,

    }

};

const mapDispatchToProps = (dispatch)  => { 

    return bindActionCreators({ searchMoqByTerm }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search));
