import React, { useState, useMemo } from "react";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet-async";
import tiktok from "../layouts/img/tiktok_black.svg";
import instagram from "../layouts/img/instagram_black.svg";
import twitter from "../layouts/img/twitter_black.svg";
import facebook from "../layouts/img/facebook_black.svg";
import FAQ_1 from "../components/FAQ_1";
import FAQ_2 from "../components/FAQ_2";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import instance from "../utils/instance";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

// Schema
const Schema = Yup.object().shape({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  subject: Yup.string().required(),
  email: Yup.string().email().required(),
  message: Yup.string().required(),
});

function Index() {
  // States
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [recaptcha, seRecaptcha] = useState(null);

  // Memo
  useMemo(() => {
    if (recaptcha !== null) {
      setDisabled(false);
    }
  }, [recaptcha]);

  // Functions
  const sendMessage = async (values, { setSubmitting, resetForm }) => {
    try {
      setLoading(true);
      let obj = {
        first_name: values?.firstname,
        last_name: values?.lastname,
        email: values?.email,
        subject: values?.subject,
        message: values?.message,
      };
      let response = await instance.post("contact_us", obj);
      if (response?.data?.message) {
        setLoading(false);
        resetForm();
        return toast.success(`${response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data &&
        typeof error?.response?.data === "object" &&
        Object.values(error?.response?.data) &&
        Object.values(error?.response?.data)?.length > 0
      ) {
        toast.error(`${Object.values(error?.response?.data)[0][0]}`, {
          autoClose: 10000,
        });
      } else {
        toast.error(`We could not complete your request. Please try again !`, {
          autoClose: 10000,
        });
      }
    }
  };

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />

        <title>Litudian | Contact Us</title>
        <meta
          name="description"
          content="Contact Litudian, Frequently Asked Questions"
        ></meta>
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Litudian | Contacts Us"></meta>
        <meta
          property="og:description"
          content="Contact Litudian, Frequently Asked Questions"
        ></meta>
        <meta
          property="og:image"
          content="https://litudian.s3.ap-south-1.amazonaws.com/1XAP3XXY9Z3V.jpg"
        ></meta>

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:domain"
          content="https://www.litudian.com"
        ></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta name="twitter:title" content="Litudian | Contacts Us"></meta>
        <meta
          name="twitter:description"
          content="Contact Litudian, Frequently Asked Questions"
        ></meta>
        <meta
          name="twitter:image"
          content="https://litudian.s3.ap-south-1.amazonaws.com/1XAP3XXY9Z3V.jpg"
        ></meta>
      </Helmet>
      <div className="flex flex-col space-y-4 md:space-y-24 pb-24 overflow-x-hidden">
        <div className="flex flex-col pt-4 dark:text-white">
          <h1 className="uppercase text-2xl font-medium text-tangerine">
            Contact Us
          </h1>
        </div>
        <div className="flex flex-col pb-5 dark:text-white space-y-8 md:space-y-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-12">
            <div className="flex flex-col justify-start order-2 md:order-1">
              <Formik
                validationSchema={Schema}
                enableReinitialize
                initialValues={{
                  firstname: "",
                  lastname: "",
                  email: "",
                  subject: "",
                  message: "",
                }}
                onSubmit={sendMessage}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form
                    className="grid grid-cols-1 md:grid-cols-4 gap-4"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="col-span-full flex flex-col">
                      <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                        Get in touch
                      </h1>
                      <p className="dark:text-gray-200 text-[#212121]">
                        We are here for you. How can we help ?
                      </p>
                    </div>
                    <TextInput
                      className="md:col-span-2"
                      label="First name"
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="e.g John"
                      error={errors.firstname}
                    />
                    <TextInput
                      className="md:col-span-2"
                      label="Last name"
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="e.g Doe"
                      error={errors.lastname}
                    />
                    <TextInput
                      className="col-span-full"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="e.g john.doe@example.com"
                      error={errors.email}
                      type="email"
                    />
                    <TextInput
                      className="col-span-full"
                      label="Subject"
                      name="subject"
                      value={values.subject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="write..."
                      error={errors.subject}
                    />
                    <TextInput
                      className="col-span-full"
                      label="Message"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Write..."
                      error={errors.message}
                      textarea={true}
                    />
                    <div className="flex flex-col items-end space-y-5 justify-between col-span-full">
                      <ReCAPTCHA
                        sitekey="6Ld7OqEpAAAAALXFFJZgSRC0aqYtjO5foEWMVQoJ"
                        onChange={(value) => seRecaptcha(value)}
                      />
                      <Button
                        type="submit"
                        disabled={disabled}
                        loading={loading}
                      >
                        Send Message
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <div className="space-y-5 order-1 md:order-2">
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                Head Office
              </h1>
              <div className="flex flex-row items-center justify-start space-x-3">
                Family Trade Center, 4th Floor - F89, Tom Mboya Street, Nairobi, Kenya
                <br />
                Email: hello@litudian.com
                <br />
                Telephone: +254792819290
                <br />
                We are available on call every Monday to Friday, 08:00 18:00,
                weekends and holidays 09:00 - 16:00
              </div>
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                Self Pickup Office
              </h1>
              <div className="flex flex-row items-center justify-start space-x-3">
                Akshrab Godowns, Gate A-2, JKIA Junction, Mombasa Road, Kenya
                <br />
                Telephone: +2547092924403
                <br />
                Business hours: Monday - Saturday: 08:00 - 17:00
              </div>
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                Connect With Us On Social Media
              </h1>
              <div className="flex flex-row items-center justify-start space-x-3">
                <a
                  href="https://www.instagram.com/litudian/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={instagram}
                    alt="instagram"
                  />
                </a>

                <a
                  href="https://web.facebook.com/litudian"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={facebook}
                    alt="facebook"
                  />
                </a>

                <a
                  href="https://www.tiktok.com/@litudiankenya"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={tiktok}
                    alt="tiktok"
                  />
                </a>

                <a
                  href="https://twitter.com/Litudian"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="h-[25px] object-contain"
                    src={twitter}
                    alt="twitter"
                  />
                </a>
              </div>
            </div>
          </div>
          <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
            Frequently Asked Questions
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-12">
            <div className="flex flex-col order-2 md:order-2">
              <FAQ_2 />
            </div>
            <div className="flex flex-col order-1 md:order-1">
              <FAQ_1 />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
