import React from "react";
import { toTitleCase } from "./../utils/helpers";
import { NumericFormat } from "react-number-format";
import ProgressBar from "@ramonak/react-progress-bar";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MOQ = ({ moq }) => {
  // Effects

  // Functions
  const getPercentage = (bids, quantity) => {
    if (bids !== undefined && quantity !== undefined) {
      let order_quantity =
        bids?.length > 0
          ? bids
              .map((item) => item?.quantity)
              .reduce((prev, next) => prev + next)
          : 0;
      return Math.trunc((order_quantity * 100) / quantity);
    }
    return 0;
  };

  function findMinMax(arr) {
    let min = 0;

    let max = 0;

    if (arr === undefined) {
      return { min, max };
    } else {
      min = arr && arr?.length > 0 ? arr[0].price : 0;

      max = arr && arr?.length > 0 ? arr[0].price : 0;
    }

    if (arr && arr?.length > 0) {
      for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i].price;
        min = v < min ? v : min;
        max = v > max ? v : max;
      }
    }

    return { min, max };
  }

  function findBelowMOQPriceMinMax(arr) {
    let min = 0;

    let max = 0;

    if (arr === undefined) {
      return { min, max };
    } else {
      min = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;

      max = arr && arr?.length > 0 ? arr[0].below_moq_price || 0 : 0;
    }

    if (arr && arr?.length > 0) {
      for (let i = 1, len = arr.length; i < len; i++) {
        let v = arr[i].below_moq_price || 0;
        min = v < min ? v : min;
        max = v > max ? v : max;
      }
    }

    return [min, max];
  }

  function getBelowMOQPrice(value) {
    let varying_sizes = value?.product[0]?.varying_sizes || 0;
    if (varying_sizes === 1) {
      let data = findBelowMOQPriceMinMax(value?.moq_attributes);
      if (data?.length === 2 && data[0] === data[1]) {
        return data[0]?.toLocaleString();
      } else {
        return `${data[0]?.toLocaleString()} - ${data[1]?.toLocaleString()}`;
      }
    } else {
      return value?.below_moq_price?.toLocaleString() || 0;
    }
  }

  let bgColor =
    getPercentage(moq?.bids, moq?.quantity) < 100 ? "#FFBF00" : "#8DC63F";

  let below_moq_price = getBelowMOQPrice(moq);

  return (
    <a
      className="cursor-pointer"
      href={`/products/${moq?.moq_slug || moq?.id}`}
      // onClick={() => (window.location.href = `/products/${moq?.id}`)}
    >
      <div className="flex flex-col shadow w-full rounded-md h-full">
        <div className="relative bg-transparent h-[160px]">
          <LazyLoadImage
            className="h-[160px] object-cover w-full rounded-t-md"
            src={
              (moq?.product && moq?.product[0]?.images[0]) ||
              "https://via.placeholder.com/300"
            }
            alt={moq.id}
            onError={(e) => {
              e.target.src = "https://via.placeholder.com/300";
            }}
          />
        </div>
        <div className="bg-white dark:bg-[#5B5B5B] rounded-b-md py-2 px-3 capitalize flex flex-col justify-between space-y-1 flex-1 h-full">
          <h4 className="text-[#5B5B5B] dark:text-white font-normal truncate">
            {moq?.product ? toTitleCase(moq?.product[0]?.name) : "-"}
          </h4>
          {moq?.product &&
          moq?.product[0] &&
          moq?.product[0]?.varying_sizes === 1 ? (
            <span className="text-[20px] text-tangerine font-bold truncate">
              KES{" "}
              {findMinMax(
                moq?.moq_attributes || moq?.moq[0]?.moq_attributes
              )?.min?.toLocaleString() || 0}{" "}
              -{" "}
              {findMinMax(
                moq?.moq_attributes || moq?.moq[0]?.moq_attributes
              )?.max?.toLocaleString() || 0}
            </span>
          ) : (
            <span className="text-md text-tangerine font-bold text-lg truncate">
              <NumericFormat
                displayType="text"
                value={moq.price > 0 ? moq?.price : 0}
                thousandSeparator=","
                prefix="KES "
              />
            </span>
          )}
          {below_moq_price !== 0 && below_moq_price !== "0" && (
            <span
              className={`text-md text-[#5B5B5B] dark:text-white font-bold text-sm truncate`}
            >
              Below MOQ price: KES {getBelowMOQPrice(moq)}
            </span>
          )}
          <span className="text-md text-[#5B5B5B] dark:text-white font-bold text-sm">
            MOQ: {moq?.quantity} items
          </span>
          {moq?.status === 0 || moq?.moq_order_status !== "not placed" ? (
            <span className="text-red-500 text-sm">Not Active</span>
          ) : (
            <span className="text-green-500 text-sm">Active</span>
          )}
          <div className="flex flex-row items-center space-x-1">
            <span className="text-md text-[#5B5B5B] dark:text-white font-bold text-sm">
              {getPercentage(moq?.bids, moq?.quantity)}%{" "}
            </span>
            <div className="flex flex-row flex-1 w-full items-center">
              <ProgressBar
                completed={getPercentage(moq?.bids, moq?.quantity)}
                className="w-full"
                bgColor={bgColor}
                height="16px"
                labelAlignment="left"
                labelClassName={`text-white text-[12px] px-1`}
                customLabel="orders"
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default MOQ;
