import React, { useState } from "react";
import Layout from "./../layouts/Main";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";
import { Helmet } from "react-helmet-async";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Index() {
  // States
  const [selected, setSelected] = useState("2023");

  // Variable
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Milestones
  const milestones = [
    {
      year: "2017",
      description:
        "Litudian was founded offering sourcing and import services to a handfull of small scale importers in a single whatsapp group in Nairobi.",
    },
    {
      year: "2018",
      description:
        "We opened a small shop in Nairobi CBD to accommodate more goods. The number of whatsapp groups increased to three (3).",
    },
    {
      year: "2019",
      description:
        "Customer base grew to more than 1500 importers, we therefore moved to a larger shop space and increased our whatsapp groups to six (6).",
    },
    {
      year: "2020",
      description:
        "Digitized order collection and dispatch using excel sheets.",
    },
    {
      year: "2021",
      description:
        "Launched an admin portal for customer tracking and order processing. We were operating with 10 Whatsapp groups for importers therefore the need for an admin portal. Customer base grew to > 2000",
    },

    {
      year: "2022",
      description:
        "Completed an accelerator program with GrowthAfrica. Customer base grew to > 3000",
    },

    {
      year: "2023",
      description:
        "Divested the retail business to focus on sourcing, launched our merchant portal for self-service to our currently more than 5000 importers.",
    },
  ];

  const team = [
    {
      name: "Abner Nyakora",
      position: "Founder/CEO",
      avatar:
        "https://litudian.s3.ap-south-1.amazonaws.com/8D8J24JBG9M8.png",
    },
    {
      name: "Brian Otieno",
      position: "Engineering Lead",
      avatar:
        "https://litudian.s3.ap-south-1.amazonaws.com/HDPZBBR3711I.png",
    },
    {
      name: "Raphael Paluku",
      position: "CSCO - ​Sourcing & Quality Lead​",
      avatar: "https://litudian.s3.ap-south-1.amazonaws.com/TG7RCR9Z8MH7.jpeg",
    },
    {
      name: "Robin Mwinzi",
      position: "Operations & Logistics​",
      avatar: "https://litudian.s3.ap-south-1.amazonaws.com/BJ2QLJ9KNET5.jpg",
    },
    {
      name: "Rachael Mwango",
      position: "​Sales Manager",
      avatar: "https://litudian.s3.ap-south-1.amazonaws.com/UY7MTV5Y1BG8.jpg",
    },
    {
      name: "Kevin Moturi",
      position: "Front End Engineer",
      avatar: "https://litudian.s3.ap-south-1.amazonaws.com/CMQ3K5C43566.JPG",
    },
  ];

  const testimonials = [
    {
      comment: `I ordered a grinder and some plates. The quality was fantastic, the products were delivered in good condition and the delivery was quick. Will definitely order from them again.`,
      name: "Roshni Peshavaria",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjWM_p_HM-FI6dMMHcZp57Jl4YX_wga_O_CeEcONNFRpJEYK=w108-h108-p-rp-mo-br100",
    },
    {
      comment: `I love the services plus the quality of goods. Thank you`,
      name: "Diana Arisa",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjWmAWATfr3fNz78v_HYNkmen69rVOajpHuiFJK4T_4X_BC1=w108-h108-p-rp-mo-br100",
    },
    {
      comment: `I have had very good experience. Good customer service and legit products. I have gotten a platform where I can purchase things at a reasonable price.`,
      name: "Pamela Obuya",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjXj-G5TFznzHJmiyPEMqG_NSvZ_ArarVEsEPYT7GODGlX_w=w108-h108-p-rp-mo-br100",
    },
    {
      comment: `Furnished my house and filled my kitchen through Litudian. Good customer service, beautiful, classy and affordable products. 10/10`,
      name: "Albina Muturi",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjXjgTfH6qCU79h_VnMfKO6cp5IawvcyJXrORRhFTBK35iMy=w108-h108-p-rp-mo-br100",
    },
    {
      comment: `The quality of their items are A1 and what you see is what you get. To be honest Litudian for life. Thank you for making our lives so much easier.`,
      name: "Sylvia Macharia",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjX7hFDRqpSU6Hv8NAnjLQeoj1_kWYrmtwoYB24Fbb0aSmY=w108-h108-p-rp-mo-br100",
    },
    {
      comment: `I received the products in good time thanks to Rachel whose communication level is top notch. The products were of good quality and I will definitely be purchasing other items soon.`,
      name: "Dafri Shiks",
      avatar:
        "https://lh3.googleusercontent.com/a-/ALV-UjW64NvDKaw7C4S4rHVZe1Ap9D7jeqMuD-nlbG6MjMGGow=w108-h108-p-rp-mo-br100",
    },
  ];

  return (
    <Layout>
      <Helmet prioritizeSeoTags>
        <meta charSet="utf-8" />

        <title>Litudian | About Litudian</title>
        <meta
          name="description"
          content="About Litudian, Team, Milestones, Testimonials"
        ></meta>
        <link rel="icon" href="/favicon.ico" />

        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Litudian | About Litudian"></meta>
        <meta
          property="og:description"
          content="About Litudian, Team, Milestones, Testimonials"
        ></meta>
        <meta
          property="og:image"
          content="https://litudian.s3.ap-south-1.amazonaws.com/1XAP3XXY9Z3V.jpg"
        ></meta>

        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta
          property="twitter:domain"
          content="https://www.litudian.com"
        ></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta name="twitter:title" content="Litudian | About Litudian"></meta>
        <meta
          name="twitter:description"
          content="About Litudian, Team, Milestones, Testimonials"
        ></meta>
        <meta
          name="twitter:image"
          content="https://litudian.s3.ap-south-1.amazonaws.com/1XAP3XXY9Z3V.jpg"
        ></meta>
      </Helmet>
      <div className="flex flex-col space-y-4 md:space-y-24 pb-24 overflow-x-hidden">
        <div className="flex flex-col pt-4 dark:text-white">
          <h1 className="uppercase text-2xl font-medium text-tangerine">
            About Litudian
          </h1>
          <p className="justify-end">We're on a mission to simplify sourcing challenges and make bulk imports a breeze. Through collaborative efforts, we're reshaping the landscape by pooling requests to reach MOQ requirements, unlocking endless opportunities for cost-effective imports.</p>
        </div>
        <div className="flex flex-col pb-5 dark:text-white space-y-8 md:space-y-24">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
            <div className="space-y-5">
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                mission
              </h1>
              <p className="font-black">
                <blockquote>
                  To offer a marketplace platform for buyers to import products
                  from different sources, in different counries, in bulk, and
                  pool together to cost save while we facilitate painless
                  end-to-end logistics.
                </blockquote>
              </p>
            </div>
            <div className="flex flex-col justify-end items-center">
              <div className="w-full md:h-[400px] bg-[#F48360]/0 rounded-md">
                <LazyLoadImage
                  className="w-full h-full object-contain"
                  src="https://litudian.s3.ap-south-1.amazonaws.com/1XAP3XXY9Z3V.jpg"
                  alt="Litudian Team"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
            <div className="flex flex-col justify-end items-center order-2 md:order-1">
              <div className="w-full md:h-[400px] bg-[#F48360]/0 rounded-md">
                <LazyLoadImage
                  className="w-full h-full object-contain"
                  src="https://litudian.s3.ap-south-1.amazonaws.com/B36REYQVEAYA.jpg"
                  alt="Litudian Office"
                />
              </div>
            </div>
            <div className="space-y-5 order-1 md:order-2">
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                Vision
              </h1>
              <p className="font-black">
                <blockquote>
                  To be Africa’s top digital sourcing entity offering complete
                  supply chain from sourcing to delivery in Africa and beyond.
                </blockquote>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
            <div className="space-y-5">
              <h1 className="uppercase text-xl font-medium text-[#986510] dark:text-tangerine">
                Purpose
              </h1>
              <p className="font-black">
                <blockquote>
                  To stimulate decent work and economic growth by offering digital
                  e-commerce and sourcing platforms and market places that empowers merchants
                  to independently and sustainably grow economically,
                  essentially by bringing global suppliers closer!
                </blockquote>
                <blockquote>
                Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all 
                <footer>
                <cite>
                  <a href="https://sdgs.un.org/goals/goal8" target="_blank" rel="noreferrer noopener">UN sustainable development goal number 8</a>
                </cite>
                </footer>
                </blockquote>
              </p>
            </div>
            <div className="flex flex-col justify-end items-center">
              <div className="w-full md:h-[400px] bg-[#F48360]/0 rounded-md">
                <LazyLoadImage
                  className="w-full h-full object-contain rounded-md"
                  src="https://litudian.s3.ap-south-1.amazonaws.com/AKYCDL6NJ4QT.png"
                  alt="Litudian Office"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
          <div className="md:col-span-1 flex flex-col space-y-2 md:space-y-4">
            <div className="flex flex-col space-y-2 md:space-y-4">
              <h1 className="uppercase text-[#5B5B5B] dark:text-[#fff] text-xl">
                MILESTONES
              </h1>
              <h1 className="capitalize text-[#F1592A] text-lg font-medium">
                Year
              </h1>
            </div>
            <div className="grid grid-cols-8 md:grid-cols-1 gap-3 w-full">
              {milestones
                ?.sort((a, b) => b?.year - a?.year)
                ?.map((m, i) => (
                  <button
                    key={i}
                    className={`uppercase ${
                      m?.year === selected
                        ? "text-[#986510]"
                        : "text-[#986510]/50"
                    } font-bold cursor-pointer w-full flex flex-row items-center justify-start`}
                    onClick={() => setSelected(m?.year)}
                  >
                    <h1>{m?.year}</h1>
                  </button>
                ))}
            </div>
          </div>
          <div className="md:col-span-4 bg-[#F9F9F9] dark:bg-[#212121] rounded-2xl">
            <div
              id="2017"
              className="flex flex-col w-full divide-y-2 dark:divide-y-[1px] dark:divide-gray-500 space-y-4 p-5 md:p-10"
            >
              <div className="flex flex-col space-y-3">
                <h1 className="text-[#986510] uppercase">Milestones</h1>
                <h1 className="capitalize text-[#F1592A] text-lg font-medium">
                  {milestones?.find((m) => m?.year === selected)?.year || ""}
                </h1>
              </div>
              <div className="flex flex-col py-5">
                <p className="dark:text-gray-400">
                  {milestones?.find((m) => m?.year === selected)?.description ||
                    ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-10 py-8 md:py-16 bg-[#F9F9F9] dark:bg-transparent md:mb-16">
          <div className="flex flex-row justify-center items-center">
            <h1 className="text-[#F1592A] uppercase font-bold text-2xl">
              Meet the team
            </h1>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-5 md:gap-10">
            {team?.map((t, i) => (
              <div
                key={i}
                className="flex flex-1 flex-col justify-center items-center space-y-2"
              >
                <div className=" bg-[#986510] rounded-2xl">
                  <LazyLoadImage src={t?.avatar} className="w-21 h-21" alt={t?.name} />
                </div>
                <h1 className="font-bold text-xl dark:text-white capitalize">
                  {t?.name}
                </h1>
                <h1 className="text-[#F1592A] uppercase font-bold text-sm">
                  {t?.position}
                </h1>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:space-y-10 py-4 md:py-16 dark:bg-transparent md:mb-16">
          <div className="flex flex-row justify-start items-center">
            <h1 className="text-[#F1592A] uppercase font-bold text-2xl">
              Testimonials
            </h1>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-10">
            <div className="col-span-full">
              <Slider {...settings}>
                {testimonials?.map((t, i) => (
                  <div
                    key={i}
                    className="flex flex-col bg-[#F9F9F9] rounded-xl"
                  >
                    <div className="bg-[#F9F9F9] rounded-t-xl p-6 flex flex-col h-[200px] min-hd:h-[200px]">
                      <p className="text-sm italic font-futura font-extrabold text-[#5B5B5B] text-center line-clamp-6">
                        "{t?.comment}"
                      </p>
                    </div>
                    <div className="bg-[#F48360] flex flex-col justify-center items-center pb-3 pt-8 rounded-xl relative">
                      <div className="bg-[#A68F69] p-2 rounded-2xl -top-10 absolute">
                        <img
                          src={t?.avatar}
                          className="w-12 h-12"
                          alt={t?.name}
                        />
                      </div>
                      <p className="text-[#010101] uppercase text-sm font-extrabold font-futuraBold">
                        {t?.name}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
